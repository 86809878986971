var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "商品详情",
          "left-text": "",
          "right-text": "",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft },
      }),
      _c(
        "van-swipe",
        { staticClass: "swipe-container", attrs: { autoplay: 3000 } },
        _vm._l(_vm.detailsData.slider_image, function (image, index) {
          return _c("van-swipe-item", { key: index }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: image,
                  expression: "image",
                },
              ],
            }),
          ])
        }),
        1
      ),
      _c("div", { staticClass: "content-box header-box" }, [
        _c("div", { staticClass: "goods-price" }, [
          _vm._v("￥" + _vm._s(_vm.detailsData.price)),
        ]),
        _c("div", { staticClass: "goods-name" }, [
          _vm._v(_vm._s(_vm.detailsData.store_name)),
        ]),
      ]),
      _c("div", { staticClass: "content-box" }, [
        _c("div", { staticClass: "content-title" }, [_vm._v("商品详情")]),
        _c("div", {
          staticClass: "content-richText package-details",
          domProps: { innerHTML: _vm._s(_vm.detailsData.store_info) },
        }),
      ]),
      _c(
        "van-goods-action",
        [
          _c(
            "van-button",
            { attrs: { type: "info", round: "" }, on: { click: _vm.buyNow } },
            [_vm._v("立即购买")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
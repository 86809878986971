import _defineProperty from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\defineProperty.js";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/goods-action/style";
import _GoodsAction from "vant/es/goods-action";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/button/style";
import _Button from "vant/es/button";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { detail } from '@/api/goods';
import { userInfo } from '@/api/user';
export default {
  name: 'Home',
  components: (_components = {}, _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _Card.name, _Card), _defineProperty(_components, _Tag.name, _Tag), _defineProperty(_components, _Toast.name, _Toast), _defineProperty(_components, _Swipe.name, _Swipe), _defineProperty(_components, _SwipeItem.name, _SwipeItem), _defineProperty(_components, _GoodsAction.name, _GoodsAction), _defineProperty(_components, _Icon.name, _Icon), _defineProperty(_components, _RadioGroup.name, _RadioGroup), _defineProperty(_components, _Radio.name, _Radio), _components),
  data: function data() {
    return {
      detailsData: {
        purchaseNotes: '有效期'
      },
      radio: '1'
    };
  },
  created: function created() {
    this.getGoodsInfo(this.$route.query.id);
  },
  methods: {
    //  获取商品详情
    getGoodsInfo: function getGoodsInfo(id) {
      var _this = this;

      detail({
        id: id
      }).then(function (res) {
        if (res.error.code === 0) {
          _this.detailsData = res.data;
        }
      });
    },
    // 跳转购买确认页
    buyNow: function buyNow() {
      var _this2 = this;

      // 暂时这样处理验证登录
      userInfo().then(function (res) {
        if (res.error.code === 0) {
          _this2.$router.push({
            name: 'confirmOrder',
            query: {
              detailsData: JSON.stringify(_this2.detailsData)
            }
          });
        }
      });
    },
    // 返回
    onClickLeft: function onClickLeft() {
      this.$router.push({
        name: 'home'
      });
    }
  }
};